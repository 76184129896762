'use strict';

/**
 * This file contains just landing logic
 */

const cookieUtils = require('./../utils/utilsCookies');

const CSS_SELECTORS = {
    bannerRedirectComponent: {
        customerLoggedCssSelector: '#bannerRedirect',
        customerNotLoggedCssSelector: '.landing-page a.bannerLogin',
    },
    inputTokenComponent: {
        elementCssSelector: '#inputToken',
        methodAttrCssSelector: 'data-method'
    },
    landingModals: {
        landingSuccessModal: {
            elementCssSelector: '#landingSuccessModal',
            htmlKey: 'landingSuccessModalHtml'
        },
        landingErrorModal: {
            elementCssSelector: '#landingErrorModal',
            htmlKey: 'landingErrorModalHtml'
        }
    },
    mainContentCssSelector: '#maincontent'
};

const COOKIE_NAMES = {
    SESSION_COOKIE_ID: 'sid',
};

const BANNER_ATTRIBUTES = {
    CLASS_TO_REMOVE: 'bannerLogin',
    ATTRIBUTES_TO_REMOVE: ['data-toggle', 'data-target', 'aria-expanded', 'data-login-action'],
    NEW_ID: 'bannerRedirect'
};

/**
 * Appends a modal to the main content and displays it if it doesn't already exist.
 *
 * @param {string} modalHtml - The HTML content of the modal to be appended.
 * @param {string} modalSelector - The CSS selector of the modal.
 * 
 * @returns {void}
 */
function appendAndShowModal(modalHtml, modalSelector) {
    const { mainContentCssSelector } = CSS_SELECTORS;

    if ($(modalSelector).length === 0) {
        $(mainContentCssSelector).append($(modalHtml));
    }
    $(modalSelector).modal('show');
}

/**
 * Handles the display of success or error modals based on the provided data.
 *
 * @param {Object} data - The response data containing modal HTML content.
 * @param {string} modalHtmlKey - The key in the data object that holds the modal HTML.
 * @param {string} modalSelector - The CSS selector of the modal to be shown.
 * 
 * @returns {void}
 */
function handleModal(data, modalHtmlKey, modalSelector) {
    if (!data.errorHtml && data[modalHtmlKey]) {
        appendAndShowModal(data[modalHtmlKey], modalSelector);
    }
}

/**
 * Retrieves the customer token from the input field and makes an AJAX call.
 * If successful, it displays the appropriate modal and redirects the user.
 *
 * @returns {void}
 */
function getCustomerToken() {
    const {
        inputTokenComponent,
        landingModals,
    } = CSS_SELECTORS;
    const { SESSION_COOKIE_ID } = COOKIE_NAMES;

    const inputToken = $(inputTokenComponent.elementCssSelector);
    const cookieValue = cookieUtils.getCookie(SESSION_COOKIE_ID);

    $.ajax({
        url: inputToken.val(),
        method: inputToken.attr(inputTokenComponent.methodAttrCssSelector),
        data: { cookieValue },
        beforeSend: () => $.spinner().start(),
        success: (data) => {
            if (data.success) {
                handleModal(data, landingModals.landingSuccessModal.htmlKey, landingModals.landingSuccessModal.elementCssSelector);
                setTimeout(() => window.location.href = data.redirectUrl, data.loadingModalDuration);
                return;
            }
            handleModal(data, landingModals.landingErrorModal.htmlKey, landingModals.landingErrorModal.elementCssSelector);
        },
        complete: () => $.spinner().stop()
    });
}

/**
 * Handle category banner click when it redirects to an external link
 *
 * @returns {void}
 */
function handleExternalCategoryBannerClick() {
    const { bannerRedirectComponent} = CSS_SELECTORS;

    $(document).on('click', bannerRedirectComponent.customerLoggedCssSelector, function (e) {
        e.preventDefault();
        getCustomerToken();
    });
}

/**
 * Resets the attributes of a banner element, removing unnecessary properties
 * and setting a new ID for redirection purposes.
 *
 * @param {jQuery} bannerElement - The jQuery object representing the banner element to be modified.
 *
 * @returns {void}
 */
function resetBannerAttributes(bannerElement) {
    bannerElement.removeClass(BANNER_ATTRIBUTES.CLASS_TO_REMOVE);
    
    BANNER_ATTRIBUTES.ATTRIBUTES_TO_REMOVE.forEach(attr => {
        bannerElement.removeAttr(attr);
    });

    bannerElement.attr('id', BANNER_ATTRIBUTES.NEW_ID);
}

/**
 * Handles the get customer token process after a user logs in.
 * 
 * @returns {void}
 */
function handleGetCustomerTokenAfterLogin() {
    const { bannerRedirectComponent } = CSS_SELECTORS;

    resetBannerAttributes($(bannerRedirectComponent.customerNotLoggedCssSelector));
    $(bannerRedirectComponent.customerLoggedCssSelector).trigger('click');
}

module.exports = {
    handleExternalCategoryBannerClick: handleExternalCategoryBannerClick,
    handleGetCustomerTokenAfterLogin: handleGetCustomerTokenAfterLogin
};
