'use strict';

/* eslint-disable max-len */

var focusHelper = require('base/components/focus');
var caProductDetail = require('../components/ca_product_detail');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el)
            .closest('.modal-content')
            .find('.product-quickview')
            .attr('data-pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $('.add-to-cart').first().attr('data-pid');
    } else {
        pid = $('.product-detail:not(".bundle-item")').attr('data-pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el)
            .closest('.product-detail')
            .find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        quantitySelected = $('.product-detail').find('.product-bundle').find('.quantity-select.form-control').last();
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    // Update DOM elements that do not require special handling
    ['.container.product-detail:not(".bundle-item")'].forEach(function (selector) {
        updateDom($results, selector);
    });
}

/**
 * Format price value updated
 * @param {string} txt - string price
 * @param {string} price - string to replace
 * @returns {string} total price to print
 */
function updatePriceFormated(txt, price) {
    var format;
    var separator = txt.indexOf(',') > -1 ? ',' : '.';
    var separator2 = txt.indexOf('.') > -1 ? ',' : '.';
    if (txt.trim().indexOf('€') > 0) {
        format = txt.replace(txt.split('€')[0].trim(), price.toFixed(2).toString().replace(separator2, separator));
        format = price > 999 ? format.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : format;
    } else {
        format = txt.replace(txt.split('€')[1].trim(), price.toFixed(2).toString().replace(separator2, separator));
        format = price > 999 ? format.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : format;
    }
    return format;
}

/**
 * Update Prices set when change variations
 */
function updateSetPrices() {
    let element = $('.ca_product_detail_info--price .sales .value')[0];
    var PVP = parseFloat(element.attributes.content.value);
    var QUOTE = parseFloat(element.firstElementChild.attributes['data-quote'].value);

    var pvpInsurance = 0;
    var quoteInsurance = 0;
    if ($('input[name="radio-seguro"]').length > 0) {
        pvpInsurance = parseFloat($('input[name="radio-seguro"]:checked').data('price'));
        quoteInsurance = parseFloat($('input[name="radio-seguro"]:checked').parent()
            .find('[data-insurance-quote]').attr('data-insurance-quote'));
    }

    var productPvp = 0;
    var productQuote = 0;
    var insurancePvpFinal = PVP;
    var insuranceQuoteFinal = 0;

    if (PVP && QUOTE) {
        PVP += pvpInsurance;
        QUOTE += quoteInsurance;
        var selection = $('.ca_product_detail_complement_select.active');
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < selection.length; i++) {
            // eslint-disable-next-line radix
            productPvp = parseFloat(selection[i].getElementsByClassName('value')[0].attributes.content.value);
            PVP += productPvp;
            insurancePvpFinal += productPvp;
            // eslint-disable-next-line max-len
            productQuote = parseFloat(selection[i].getElementsByClassName('value')[0].firstElementChild.attributes['data-quote'].value);
            QUOTE += productQuote;
            insuranceQuoteFinal += productQuote;
        }
    }

    var finalPrice = $('.ca_product_detail_btn_actions')[0];
    if (finalPrice) {
        $(finalPrice).find('.value').children().text(
            function (i, txt) {
                return updatePriceFormated(txt, QUOTE);
            }
        );
        $(finalPrice).find('.product-pvp span').text(
            function (i, txt) {
                return updatePriceFormated(txt, PVP);
            }
        );
    }

    var insuranceFinalPrice = $('.ca_secure_pdp');
    var separator;
    var separator2;
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < insuranceFinalPrice.length; j++) {
        const item = insuranceFinalPrice[j];
        $(item).find('.product-pvp span').text(
            // eslint-disable-next-line no-loop-func
            function (i, txt) {
                separator = txt.indexOf(',') > -1 ? ',' : '.';
                separator2 = txt.indexOf('.') > -1 ? ',' : '.';
                return updatePriceFormated(txt, insurancePvpFinal);
            }
        );
        var insuranceQuote = (parseFloat($(item).find('[data-quote]').attr('data-quote'))
            + insuranceQuoteFinal).toFixed(2).replace(separator2, separator);
        $(item).find('[data-quote]').html(insuranceQuote.toString().replace(separator2, separator));
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer
        });
        var setProducts = $('input[name="radio-seguro"][value="0"]');
        // eslint-disable-next-line max-len
        var selectedNewValueUrl = selectedValueUrl.concat('&productMainID=' + $('input[name=mainProductID]').val() + '&oldVariant=' + $('input[name=oldVariant]').val());
        // $.ajax({
        //     url: selectedNewValueUrl,
        //     method: 'GET',
        //     success: function (data) {
        //         if (data.html) {
        //             var setContainer = $('.ca_set_container');
        //             parseResults(data.html);
        //             var newPid = data.pid;
        //             if (data.productType !== 'set' || !data.changingComplement) {
        //                 $('.ca_set_wrapper').empty().append(setContainer);
        //                 if (data.productType === 'set') {
        //                     var dataMasterPid = $('[data-master-pid]');
        //                     for (let j = 0; j < dataMasterPid.length; j++) {
        //                         const item = dataMasterPid[j];
        //                         $(item).attr('data-master-pid', newPid);
        //                     }
        //                 }
        //                 $('.container.product-detail').attr('data-pid', newPid);
        //             } else {
        //                 $('.product-complement.ca_product_detail_complement_select').attr('data-pid', data.changingComplement);
        //                 $('.page').data('querystring', 'pid=' + newPid);
        //             }
        //             $('.product-complement.ca_product_detail_complement_select').removeClass('active');
        //             caProductDetail.selectSimulationQuota();
        //             if ($('.product-set-detail').length > 0) {
        //                 setProducts.trigger('click');
        //             }
        //         }
        //         $.spinner().stop();
        //     },
        //     error: function () {
        //         $.spinner().stop();
        //     }
        // });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getCheckoutBeginUrl() {
    return $('.checkout-begin-url').val();
}

/**
 * Parses the html
 * @param {string} html - representing the html of product variation
 *
 * @return {Object} - Object with product properties.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="'
        + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">'
        + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(
                response.enterDialogMessage
            );
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(
                response.closeButtonText
            );
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the 'Add to Cart' button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {Object} button - cart button
 */
function handlePostCartAdd(response, button) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (
        response.newBonusDiscountLineItem && Object.keys(response.newBonusDiscountLineItem).length !== 0
    ) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if (response.error !== true) {
        if (button === null || !button.hasClass('ca-btn-checkout')) {
            var bodyHtml = response.html;
            $('#addFirstProduct .modal-body').html(bodyHtml);
            $('#addFirstProduct').modal('show');
        }
    } else if (!$('.add-to-cart-messages').length) {
        $('body').append('<div class="add-to-cart-messages"></div>');
        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message + '</div>'
        );
        setTimeout(function () {
            $('.add-to-cart-messages').remove();
        }, 5000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 * @param {jquery} $el - DOM container for the products in the bundle
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts($el) {
    var childProducts = [];
    $el.find('.bundle-item').each(function () {
        var pid = $(this).find('.product-id').text() !== '' ? $(this).find('.product-id').text() : $(this).attr('data-pid');
        var qty = $(this).find('label.quantity').data('quantity') !== undefined ? $(this).find('label.quantity').data('quantity') : 1;
        childProducts.push({
            pid: pid,
            quantity: parseInt(
                qty,
                10
            )
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer.find('.product-option').map(function () {
        // var $elOption = $(this).find('input[type="radio"]:checked');
        var selectedValueId = $('option:selected', this).data('value-id');
        // var selectedValueId = $elOption.data('value-id');
        return {
            optionId: $(this).data('option-id'),
            selectedValueId: selectedValueId
        };
    }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * get information from the cart
 *
 */
function cartInfo() {
    const actionUrl = $('.basket-get-action').attr('data-url');

    if (actionUrl) {
        $.ajax({
            url: actionUrl,
            type: 'get',
            dataType: 'json',
            beforeSend: () => $.spinner().start(),
            success: function (data) {
                $('#removeProductModal').modal('show');
                if (!data.error) {
                    var productID = data.result.id;
                    var productName = data.result.name;
                    var uuid = data.result.uuid;
    
                    var $productToRemoveSpan = $('.product-to-remove');
                    var $deleteConfirmBtn = $('.cart-delete-replace-confirmation-button');
    
                    $deleteConfirmBtn.attr('pid', productID);
                    $deleteConfirmBtn.attr('action', $('.product-delete-action').attr('data-url'));
                    $deleteConfirmBtn.attr('uuid', uuid);
    
                    $productToRemoveSpan.empty().append(productName);
                }
            },
            error: function (err) {
                if (err.responseJSON && err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
            },
            complete: () => $.spinner().stop()
        });
    }
}

/**
 * addToCart function
 *
 * @param {jQuery} $this - DOM element for addToCart button
 */
function addToCartFunction($this) {
    var minicartCountOfItems = $('.minicart-quantity').text().trim();

    if (minicartCountOfItems > 0) {
        $('.modal-backdrop').css('z-index', '1');
        $('.prices-add-to-cart-actions').css('z-index', 'auto');
        $('.cart-and-ipay').data('class-ref', $($this).attr('class'));
        // $('#removeProductModal').modal('show');
        cartInfo();
    } else {
        var addToCartUrl;
        var checkoutBeginUrl;
        var pid;
        var pidsObj;
        var setPids;
        var button = $($this);

        // BL000664 Fase 2 VO
        const isVOPDP = $('.pdp-vo.product-detail');
        if (isVOPDP.length) {
            $('.ca_product_detail_btn_actionsVO')
                .attr('disabled', true)
                .addClass('btn_actionsVO_disabled');
        } else {
            $('body').trigger('product:beforeAddToCart', $this);
        }

        var $productContainer = $($this).closest('.product-detail');
        if ($('.set-items').length) {
            setPids = [];
            setPids.push({
                pid: $('.product-detail').first().attr('data-pid'),
                mid: $('.product-detail').first().attr('data-master-pid'),
                qty: $('.add-to-cart').closest('.product-detail').find('.quantity-select').val(),
                options: getOptions($productContainer)
            });
            $('.product-detail.set-item').find('.ca_product_detail_complement_select.active').each(function () {
                if ($(this).hasClass('bundle-items')) {
                    setPids.push({
                        pid: $(this).closest('.set-item').attr('data-pid'),
                        mid: $(this).closest('.set-item').attr('data-master-pid'),
                        childProducts: JSON.parse(getChildProducts($('.product-detail').first())),
                        qty: $('.add-to-cart').closest('.product-detail').find('.quantity-select').val(),
                        options: getOptions($(this))
                    });
                } else {
                    setPids.push({
                        pid: $(this).closest('.set-item').attr('data-pid'),
                        mid: $(this).closest('.set-item').attr('data-master-pid'),
                        qty: $('.add-to-cart').closest('.product-detail').find('.quantity-select').val(),
                        options: getOptions($(this))
                    });
                }
            });
            if ($('input[name="radio-seguro"]:checked').length > 0) {
                if ($('input[name="radio-seguro"]:checked').val() !== '0') {
                    var activeSecureInput = $('input[name="radio-seguro"]:checked');
                    setPids.push({
                        pid: activeSecureInput.closest('.ca_secure_pdp').attr('data-pid'),
                        mid: activeSecureInput.closest('.ca_secure_pdp').attr('data-master-pid'),
                        qty: $('.add-to-cart').closest('.product-detail').find('.quantity-select').val(),
                        options: getOptions(activeSecureInput)
                    });
                }
            }
            pidsObj = JSON.stringify(setPids);
        }
        pid = getPidValue($($this));

        if (!$productContainer.length) {
            $productContainer = $($this)
                .closest('.quick-view-dialog')
                .find('.product-detail');
        }
        addToCartUrl = getAddToCartUrl();

        var realProductsObject = JSON.parse(pidsObj || '[]');
        var mostImpId = realProductsObject.length > 0 ? realProductsObject[0].pid : null;
        checkoutBeginUrl = getCheckoutBeginUrl();

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            mostImpId: mostImpId,
            // childProducts: getChildProducts(),
            childProducts: $('.set-items').length ? [] : getChildProducts($('.bundle-items')),
            quantity: getQuantitySelected($($this)),
            csrf_token: $('[name*=token]').val()
        };

        if ($($this).data('mostimpid')) {
            mostImpId = $($this).data('mostimpid');
            var cont = $('body').find('[data-pid="' + mostImpId + '"');
            if (cont.length > 0) {
                mostImpId = cont.find('.product-id').html();
            }
        }
        form.options = getOptions($productContainer);

        var that = $this;
        $($this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                dataType: 'json',
                success: function (data) {
                    if (data.loggedin && !data.error) {
                        handlePostCartAdd(data, button);
                        $('body').trigger('product:afterAddToCart', data);
                        miniCartReportingUrl(data.reportingURL);
                        if (button.hasClass('ca-btn-checkout') && data.error !== true) {
                            window.location.href = checkoutBeginUrl;
                        }
                    } else if (!data.loggedin && !data.error) {
                        $('#customerLoginModal').modal('show');
                    } else {
                        $(that).parent().after('<p style="color:red;">' + data.message + '</p>');
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    }
}

/**
 * Resize height of carousel items for videos
 */
function resizeCarouselItemOfVideo() {
    if ($('.product-detail .carousel[id^=pdpCarousel] .carousel-video-inner-PDP').length) {
        const $carousel = $('.product-detail .carousel[id^=pdpCarousel]');

        if ($carousel.find('.carousel-item:not([class*=video])').length) {
            const $carouselItemWithPicture = $carousel.find('.carousel-item:not([class*=video])').first();
            const carouselItemWithPictureHeight = $carouselItemWithPicture.outerWidth();
            $carousel.find('.carousel-item.carousel-video-inner-PDP').outerHeight(carouselItemWithPictureHeight);
        }
    }
}

module.exports = {
    attributeSelect: attributeSelect,
    addToCartFunction: addToCartFunction,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal')
                .siblings()
                .attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $('body').on(
            'hidden.bs.modal',
            '#chooseBonusProductModal',
            function () {
                $('#chooseBonusProductModal')
                    .siblings()
                    .attr('aria-hidden', 'false');
            }
        );
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            var colorUrl = $(this).attr('data-url');
            var colorSelected = $('.color-value.selected').attr('data-attr-value');
            if (typeof colorSelected !== 'undefined') {
                colorUrl = colorUrl.replace('Color=&', 'Color=' + colorSelected + '&');
            }
            attributeSelect(colorUrl, $productContainer);
        });
    },

    selectAttribute: function () {
        $(document).on(
            'change',
            'select[class*="select-"], .options-select',
            function (e) {
                e.preventDefault();
                let url = new URL(e.target.value);
                const isSetWithVariantComplementsPDP = $('.product-set-detail.set-with-variant-complements').length;
                const isSetWithoutVariantComplementsPDP = $('.product-set-detail.set-without-variant-complements').length;

                if (isSetWithVariantComplementsPDP || isSetWithoutVariantComplementsPDP) {
                    const $el = $(e.currentTarget);
                    const mainProdOfSetWithVariantComplWrapper = '.product-set-detail.set-with-variant-complements .attribute';
                    const mainProdOfSetWithoutVariantComplWrapper = '.product-set-detail.set-without-variant-complements .attribute';
                    const isSetProdWithVariantCompl = $(`${mainProdOfSetWithVariantComplWrapper}`).length;
                    const isSetProdWithoutVariantCompl = $(`${mainProdOfSetWithoutVariantComplWrapper}`).length;
                    const isComplProdSelector = $el.closest('.set-items').length;
                    const isMainProdSelector = !isComplProdSelector;
                    let selectedMainProductPid = $('.container.product-detail.product-set-detail').attr('data-pid');

                    if (isMainProdSelector) {
                        selectedMainProductPid = $el.attr('data-pid') ? $el.attr('data-pid') : $el.find(':selected').attr('data-pid');
                    } else if (isComplProdSelector && (isSetProdWithVariantCompl || isSetProdWithoutVariantCompl)) {
                        if (isSetProdWithVariantCompl) {
                            selectedMainProductPid = $($(`${mainProdOfSetWithVariantComplWrapper} a:has(span.selected)`)[0]).attr('data-pid');
                        } else if (isSetProdWithoutVariantCompl) {
                            selectedMainProductPid = $($(`${mainProdOfSetWithoutVariantComplWrapper} a:has(span.selected)`)[0]).attr('data-pid');
                        }
                    }

                    if (url.searchParams.has('oldVariant') && selectedMainProductPid) {
                        url.searchParams.set('oldVariant', selectedMainProductPid);
                    }
                }
                window.location.href = url.toString();
            }
        );

        $(document).on('change', '#variationAtt', function (e) {
            e.preventDefault();
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            var url = $("input[type='radio'][name='variationAtt']:checked").val();
            var colorSelected = $('.Color-value.selected').attr('data-attr-value');
            if (typeof colorSelected !== 'undefined') {
                // eslint-disable-next-line no-useless-escape
                url = url.replace(/(dwvar_49_Color=)[^\&]+/, '$1' + colorSelected);
            }
            attributeSelect(url, $productContainer);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this)
                    .closest('.modal-content')
                    .find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect(
                    $(e.currentTarget).find('option:selected').data('url'),
                    $productContainer
                );
            }
        });
    },

    visibility: function () {
        $('.price .value').each(function () {
            var priceValue = $(this).attr('content');
            if (priceValue === 'null') {
                $(this).parents('.price').siblings('.noPrice').removeClass('d-none');
                $(this).parents('.price').addClass('d-none');
                $('.ca_product_detail_btn_actions').addClass('d-none');
            } else {
                $(this).parents('.price').siblings('.noPrice').addClass('d-none');
                $(this).parents('.price').removeClass('d-none');
                $('.ca_product_detail_btn_actions').removeClass('d-none');
            }
        });
    },

    addToCart: function () {
        $(document).on(
            'click',
            'button.add-to-cart:not(".myboxForeign"), button.add-to-cart-global',
            function () {
                addToCartFunction(this);
            }
        );
    },

    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents(
                '.choice-of-bonus-product'
            );
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt(
                $choiceOfBonusProduct.find('.bonus-quantity-select').val(),
                10
            );
            var totalQty = 0;
            $.each(
                $(
                    '#chooseBonusProductModal .selected-bonus-products .selected-pid'
                ),
                function () {
                    totalQty += $(this).data('qty');
                }
            );
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct
                .find('.product-option')
                .data('option-id');
            var valueId = $choiceOfBonusProduct
                .find('.options-select option:selected')
                .data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = '<div class="selected-pid row" '
                    + 'data-pid="' + pid + '"'
                    + 'data-qty="' + submittedQty + '"'
                    + 'data-optionID="' + (optionID || '') + '"'
                    + 'data-option-selected-value="' + (valueId || '') + '"'
                    + '>'
                    + '<div class="col-sm-11 col-9 bonus-product-name" >'
                    + $choiceOfBonusProduct.find('.product-name').html() + '</div>'
                    + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
                    + '</div>';
                $('#chooseBonusProductModal .selected-bonus-products').append(
                    selectedBonusProductHtml
                );
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass(
                    'alert-danger"'
                );
            } else {
                $('.selected-bonus-products .bonus-summary').addClass(
                    'alert-danger'
                );
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $(
                '#chooseBonusProductModal .selected-bonus-products .selected-pid'
            );
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass(
                'alert-danger'
            );
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr(
                'disabled',
                !response.product.readyToOrder || !response.product.available
            );
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data(
                'pid',
                pid
            );
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $(
                '.choose-bonus-product-dialog .selected-pid'
            );
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption = parseInt($(this).data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if (
                        $(this).data('optionid') && $(this).data('option-selected-value')
                    ) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data(
                            'option-selected-value'
                        );
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt(
                        $('.pre-cart-products').html(),
                        10
                    );
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append(
                                '<div class="add-to-cart-messages"></div>'
                            );
                        }
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center"'
                            + ' role="alert">' + data.errorMessage + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass(
                            'hidden-xl-down'
                        );
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append(
                                '<div class="add-to-cart-messages"></div>'
                            );
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"'
                            + ' role="alert">' + data.msgSuccess + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                // eslint-disable-next-line no-restricted-globals
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl,
    getAddToCartUrl: getAddToCartUrl,
    getCheckoutBeginUrl: getCheckoutBeginUrl,
    cartInfo: cartInfo,
    getChildProducts: getChildProducts,
    handlePostCartAdd: handlePostCartAdd,
    resizeCarouselItemOfVideo: resizeCarouselItemOfVideo
};
