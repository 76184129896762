'use strict';

/**
 * Search into cookies if the given cookie is already created
 * @param {Array} cookiesArray - Array with cookies
 * @param {string} cookieName - The name of the searched cookie
 * @returns {boolean} result of the search
 */
function isCookieAlreadySet(cookiesArray, cookieName) {
    return cookiesArray.some(cookie => {
        return cookie.startsWith(cookieName);
    });
}

/**
 * Get the value of the given cookie
 * @param {Array} cookiesArray - Array with cookies
 * @param {string} cookieName - The name of the searched cookie
 * @returns {string} the value of the cookie
 */
function getCookieValue(cookiesArray, cookieName) {
    return cookiesArray.filter(cookie => {
        return cookie.startsWith(cookieName);
    })[0].split('=')[1];
}

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

module.exports = {
    isCookieAlreadySet,
    getCookieValue,
    getCookie
};
